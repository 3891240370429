import _baseExtremum from "./_baseExtremum";
import _baseLt from "./_baseLt";
import _identity from "./identity";
var exports = {};
var baseExtremum = _baseExtremum,
  baseLt = _baseLt,
  identity = _identity;

/**
 * Computes the minimum value of `array`. If `array` is empty or falsey,
 * `undefined` is returned.
 *
 * @static
 * @since 0.1.0
 * @memberOf _
 * @category Math
 * @param {Array} array The array to iterate over.
 * @returns {*} Returns the minimum value.
 * @example
 *
 * _.min([4, 2, 8, 6]);
 * // => 2
 *
 * _.min([]);
 * // => undefined
 */
function min(array) {
  return array && array.length ? baseExtremum(array, identity, baseLt) : undefined;
}
exports = min;
export default exports;